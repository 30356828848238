const routes = [
    {
        path: '/inscription/:slug', name: 'signup',
        props: to => ({value: to.params}),
        component: () => import('@/views/Signup')
    },
];

export default [

]