import client from '@/services/';
import Vue from 'vue';

const state = () => ({
    current: {},
    profile: {},
    logged: !!Vue.$cookies.get('ahiCCIBlido'),
    out: {},

});

const getters = {
    user: state => state.current,
    profile: state => state.profile,
    connected: state => state.logged,
    out: state => state.out,
    
};

const mutations = {
    SET_CONNECTED: (state, obj) => {
        state.current = obj;
    },
    SET_PROFILE: (state, obj) => {
        state.profile = obj;
    },
    SET_STATUS: (state, obj) => {
        state.logged = obj != null;
    },
    SET_OUT: (state, obj) => {
        state.out = obj;
    },
};

const actions = {
    signin: async (cxt, obj = {}) => {
        return client.post('/api/auth/signin', obj).then(({ data }) => {
            localStorage.setItem('user', JSON.stringify(data));
            cxt.commit("SET_CONNECTED", data);
            return data;
        });
    },
    signup(cxt, payload) {
        return client.post('/api/auth/signup', payload).then(({ data }) => { return data; });
    },
    reset(cxt, payload) {
        return client.post('/api/auth/resetpassword', payload).then(({ data }) => { return data; });
    },
    profile(cxt, payload) {
        return client.get('user/connected').then(({ data }) => {
            if (data) {
                cxt.commit("SET_PROFILE", data);
                return data;
            }
        });
    },
    editProfile(cxt, payload) {
        return client.put('user/update/profil/' + payload.id, payload).then(({ data }) => {
            cxt.commit("SET_PROFILE", data);
            return data;
        });
    },
    logout: ({ commit }) => new Promise(resolve => {
        localStorage.removeItem('user');
        Vue.$cookies.remove("ahiCCIBlido");
        commit("SET_CONNECTED", {});
        commit("SET_PROFILE", {});
        commit("SET_STATUS", null);
        resolve();
    }),
    resetPassword: async (cxt, email) => {
        return client.post(`/api/auth/reinit?email=${email}`).then(({ data }) => {
            cxt.commit("SET_OUT", data);
            return data;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
